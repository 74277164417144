import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { mapBlogs } from "../../lib/utility";
import BlogRoll from "../../components/BlogRoll";

import Layout from "../../components/Layout";
import HeaderSeniorLending from "../../components/HeaderSeniorLending";
import ProductHero from "../../components/ProductHero";
import ProductDetails from "../../components/ProductDetails";
import ReverseMortgageForm from "../../components/ReverseMortgageForm";

import background from "../../images/baySeniorLendingbg.jpg";
import icon from "../../images/typesImg/Icons_ReverseMortgage_active.png";
import styles from "./styles.module.less";

const ReverseMortgage = ({ data }) => {
    const header = <HeaderSeniorLending />;
    const intro = "Reverse Mortgage";
    const subhead = (
        <>
            <p>
                Access your home&apos;s equity
                <br /> with no monthly mortgage payments.
            </p>
            <br />
            <p className={styles.CallToAction}>
                <span className={styles.RwdLine}>CALL TODAY FOR A </span>
                <span className={styles.RwdLine}>NO-OBLIGATION REVERSE </span>
                <span class={styles.RwdLine}>MORTGAGE CONSULTATION</span>
                <span>
                    <a href="tel:+15202458830">520-245-8830</a>
                </span>
            </p>
            <p>
                <a
                    className={styles.CallToActionLink}
                    href="#reverseMortgageForm">
                    Or click here to have someone contact you!
                </a>
            </p>
        </>
    );

    const { corpBlogs } = data;
    const { edges: corpBlobPosts = [] } = corpBlogs || {};
    const corporateBlogs = mapBlogs(corpBlobPosts);

    return (
        <Layout hasHero={true} path="/bayseniorlending" header={header}>
            <Helmet>
                <title>
                    Reverse Mortgage - Reverse Mortgage Solutions | Bay Equity
                    Home Loans
                </title>
                <meta
                    name="description"
                    content="A reverse mortgage is a type of home loan that allows you convert a portion of the equity in your home into cash Want to find out if you qualify? Contact us today."
                />
                <script src="https://secure.adnxs.com/seg?add=24083627&t=1"></script>
                <script src="https://secure.adnxs.com/px?id=1387846&t=1"></script>
                <script type="text/javascript">{`!function(){function t(){var t=r("utm_content");if(t){var e=new Date;e.setDate(e.getDate()+30),document.cookie=t+";expires="+e.toGMTString()+";path=/"}else if(document.cookie)for(var o=document.cookie.split(/; */),n=0;n<o.length;n++)if(0===o[n].toLowerCase().trim().indexOf("utm_content=")){t=o[n];break}return t}function e(t){try{console.log(t)}catch(e){alert(t)}}function r(t){var e=top.location.search?top.location.search.substring(1):null;if(e)for(var r=e.split("&"),o=0;o<r.length;o++)if(0===r[o].toLowerCase().trim().indexOf(t+"="))return r[o];return null}var o="",n=r("mctest");if(n)e("dnr tag version: 20160125"),o="http://localhost:8080/rip/library/dnr/mcDnrTag.debug.js";else{var a=t(),c="";a&&(c=top.location.search?0<=top.location.search.indexOf("utm_content")?top.location.search:top.location.search+"&"+a:"?"+a,o="https://script.advertiserreports.com/redirector/dnr"+c)}if(o){var i=document.createElement("script");i.src=o,i.type="text/javascript",scriptTag=document.getElementsByTagName("script")[0],scriptTag.parentNode.appendChild(i)}}();`}</script>
                <script>{`
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '409846757076667');
                    fbq('track', 'PageView');
                `}</script>
                <noscript>{`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=409846757076667&ev=PageView&noscript=1"/>`}</noscript>
            </Helmet>
            <ProductHero
                customPhoto={background}
                icon={icon}
                header={intro}
                subhead={subhead}
                alt="Reverse Mortage | Bay Equity Home Loans"
            />
            <section className={styles.Intro}>
                <div className={styles.Container}>
                    <h2>
                        Whether using HUD’s Home Equity Conversion Mortgage
                        (HECM) or Bay Equity’s proprietary product line of
                        reverse mortgages, we have solutions.
                    </h2>
                    <ul className={styles.firstList}>
                        <li>
                            <p>
                                Available to seniors 60 and older (62 in TX and
                                NC)
                            </p>
                        </li>
                        <li>
                            <p>No monthly mortgage payments</p>
                        </li>
                        <li>
                            <p>
                                Flexible availability of funds (monthly
                                payments, line of credit, cash, or a blend of
                                the options to fit your needs)
                            </p>
                        </li>
                        <li>
                            <p>No maximum property value</p>
                        </li>
                        <li>
                            <p>
                                HUD and non-HUD approved condominiums available
                            </p>
                        </li>
                    </ul>

                    <h2 className={styles.introSubHead}>
                        Purchase a new primary residence or access the equity in
                        your current home
                    </h2>
                    <h2>Use the funds for any purpose, including:</h2>
                    <ul>
                        <li>
                            <p>
                                Supplement to your monthly income with tax-free
                                proceeds
                            </p>
                        </li>
                        <li>
                            <p>Access to cash for sudden expenses</p>
                        </li>
                        <li>
                            <p>
                                Eliminate your current monthly mortgage payment
                            </p>
                        </li>
                        <li>
                            <p>Making repairs or improvements to your home</p>
                        </li>
                        <li>
                            <p>
                                Purchase of a second residence, automobile, or
                                RV
                            </p>
                        </li>
                        <li>
                            <p>
                                Pay for needed medical and dental care and
                                prescription medications
                            </p>
                        </li>
                        <li>
                            <p>Pay for in-home health care</p>
                        </li>
                        <li>
                            <p>Travel</p>
                        </li>
                    </ul>
                </div>
            </section>
            <ProductDetails
                title={"Our Reverse Mortgage Specialists"}
                border={true}>
                <p>
                    Since 1999,{" "}
                    <a href="/smart-team">
                        <span className={styles.color}>Glen Smart, CRMP</span>
                    </a>
                    <sup>®</sup>and{" "}
                    <a href="/smart-team">
                        <span className={styles.color}>Robin Loomis, CRMP</span>{" "}
                        <sup>®</sup>
                    </a>{" "}
                    have specialized in helping seniors with reverse mortgages.
                    Both are holders of the exclusive Certified Reverse Mortgage
                    Professional (CRMP) designation from the National Reverse
                    Mortgage Lenders Association. This husband and wife team are
                    passionate about meeting the short- and long term financial
                    goals of seniors, and if a reverse mortgage offers a
                    solution, they will work to make the process of
                    understanding your options and closing the loan smooth.
                </p>
            </ProductDetails>
            <ProductDetails
                title={
                    "Certified Reverse Mortgage Professional (CRMP) designation"
                }
                border={true}>
                <p>
                    Working with a Certified Reverse Mortgage Professional (CRMP
                    <sup>®</sup>) is crucial to smoothly closing on the best
                    option for you. The CRMP designation is the highest
                    designation from the National Reverse Mortgage Lenders
                    Association and is issued to an elite cadre of mortgage
                    lenders who have demonstrated their knowledge and competency
                    in the area of reverse mortgage lending and a dedication to
                    upholding highest standards of ethical and professional
                    behavior. There are just over 100 designees nationwide.
                </p>
            </ProductDetails>
            <ProductDetails
                title={"Our Code of Ethics for reverse mortgages"}
                border={true}>
                <p>
                    Bay Equity is a proud member in good standing with the
                    National Reverse Mortgage Lenders Association and subscribes
                    to it&apos;s comprehensive{" "}
                    <a href="http://services.nrmlaonline.org/NRMLA_Documents/NRMLA%20Code%20of%20Ethics-FINAL.pdf">
                        <span className={styles.color}>Code of Ethics.</span>
                    </a>{" "}
                </p>
            </ProductDetails>
            <ProductDetails
                title={"How much money can I receive and how?"}
                border={true}>
                <p>
                    Reverse Mortgages are highly customizable to fit your
                    situation. The amount of money available is based on the age
                    of the younger borrower, the value of the home, and current
                    interest rates. The proceeds must first be used to pay off
                    any existing loans or liens against the property and the
                    remaining funds can be disbursed in any number of ways. Some
                    seniors choose to take a guaranteed payment for life, others
                    choose to take it for a specific term. There is also the
                    ability to draw cash at closing or leave the funds in a
                    growing line of credit for future use. The more common way
                    is to blend these choices.
                </p>
            </ProductDetails>
            <ProductDetails
                title={"Will I give up the ownership of my home?"}
                border={true}>
                <p>
                    Like traditional home loans or lines of credit, you don’t
                    give up the ownership of your home. A reverse mortgage is
                    simply a lien against your home. You are free to refinance,
                    pay off the loan without penalty should you wish (although
                    no monthly payments are required) or sell the home should
                    your needs change. You will still be able to pass the home
                    to your heirs. In the event that more is owed on the loan
                    balance than the future value of the home, neither you nor
                    your estate will have to make up the difference.
                </p>
            </ProductDetails>
            <ProductDetails
                title={"Who pays the property taxes and insurance on the home?"}
                border={true}>
                <p>
                    Because you will continue to own the home, you are
                    responsible for payment of property taxes, homeowner’s
                    insurance, Homeowners Association due (if any) will continue
                    to be your responsibility. You may elect to have a portion
                    of the funds set aside for future payment of taxes and
                    insurance.
                </p>
            </ProductDetails>
            <ProductDetails
                title={"When does the loan come due?"}
                border={true}>
                <p>
                    The loan will continue as long as at least one borrower
                    lives in the property as a primary residence, or in the case
                    of a non-borrowing spouse, while that spouse remains in the
                    home. Otherwise, the loan will come due only when the
                    homeowner fails to pay property taxes, insurance, property
                    related charges, or fails to comply with the terms of the
                    financing instruments.
                </p>
            </ProductDetails>
            <ProductDetails
                title={
                    "Are the funds I receive taxable? Does receipt of the money affect my Social Security, Medicaid, or retirement income?"
                }
                border={true}>
                <p>
                    Because the funds are the proceeds of a loan, they are not
                    considered taxable income and will not affect Social
                    Security, Medicaid, or pension income. Some forms of
                    subsidized Medicaid have restrictions on cash on hand so we
                    will work with you to structure the loan in such a way as to
                    avoid any reduction in services.
                </p>
            </ProductDetails>
            <ProductDetails
                title={
                    "Should I consider a reverse mortgage even if I don’t “need” the funds today?"
                }
                border={true}>
                <p>
                    One of the ways that seniors use the reverse mortgage is by
                    leaving the funds available for future use in a growing line
                    of credit. Any unused funds available in the line will grow
                    over time, resulting in a larger cash amount in the future.
                    Some seniors use this line of credit to offset investment
                    risks, fund short-term purchases, or to simply let the line
                    grow in value as a hedge against future expenses.
                    Strategically used, a reverse mortgage can serve as a
                    backstop against portfolio declines or exhaustion.
                </p>
            </ProductDetails>
            <ProductDetails title={"Reverse Mortgage Resources"}>
                <div>
                    <a href="https://www.ncoa.org/wp-content/uploads/FINAL-2018-NCOA-Reverse-Mortgage-Booklet.pdf">
                        <span className={styles.color}>
                            National Council on Aging
                        </span>
                    </a>
                </div>
                <div />
                <div>
                    <a href="https://www.nrmlaonline.org/wp-content/uploads/2019/01/NRMLA_Reverse-Mortgage-Self-Evaluation_FINAL.pdf">
                        <span className={styles.color}>
                            National Reverse Mortgage Lenders Association: Self
                            Evaluation
                        </span>
                    </a>
                </div>
                <div>
                    <a href="https://www.nrmlaonline.org/wp-content/uploads/2018/03/NRMLA_HomeToolkit_032218.pdf">
                        <span className={styles.color}>
                            National Reverse Mortgage Lenders Association:
                            Toolkit for Choosing the Right Home Environment
                        </span>
                    </a>
                </div>
                <div>
                    <a href="https://www.nrmlaonline.org/wp-content/uploads/2018/06/NRMLA_RecognizeReport_TriFold_Brochure.pdf">
                        <span className={styles.color}>
                            National Reverse Mortgage Lenders Association:
                            Recognize &amp; Report Financial Abuse
                        </span>
                    </a>
                </div>
                <div>
                    <a href="https://www.nrmlaonline.org/wp-content/uploads/2019/01/What-Do-I-Do-When-My-Loan-is-Due.pdf">
                        <span className={styles.color}>
                            National Reverse Mortgage Lenders Association: What
                            Do I Do When My Loan is Due?
                        </span>
                    </a>
                </div>
            </ProductDetails>
            <div id="reverseMortgageForm"> </div>
            <ReverseMortgageForm />
            {corporateBlogs.length >= 3 && (
                <BlogRoll
                    corporateBlogs={corporateBlogs}
                    blogs={corporateBlogs}
                    showBlogs={true}
                    word="our"
                    url="reverse-mortgage"
                />
            )}
            <div className={styles.disclosure}>
                This advertising material is not from HUD or the HFA, and this
                document is not approved by HUD or by the FHA or any other
                Federal Agency
            </div>
        </Layout>
    );
};

export default ReverseMortgage;

export const pageQuery = graphql`
    query BaySeniorLendingQuery {
        corpBlogs: allBlog(
            sort: { fields: [date], order: DESC }
            filter: {
                type: { eq: "corporate" }
                tags: { in: ["Reverse Mortgage"] }
            }
        ) {
            edges {
                node {
                    id
                    html
                    slug
                    title
                    date
                    feature
                    categories
                    tags
                    image
                    author {
                        slug
                        profile {
                            name
                        }
                        gallery {
                            photo
                        }
                    }
                    date
                    feature
                }
            }
        }
    }
`;
