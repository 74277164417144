import React, { Component } from "react";
import "url";
import "isomorphic-fetch";

import ErrorMessage from "../../ContactForm/ErrorMessage";

import { noop } from "lodash";

import styles from "../styles.module.less";

class InnerForm extends Component {
    static defaultProps = {
        complete: false,
        handleChange: noop,
        name: "",
        dateOfBirth: "",
        propertyAddress: "",
        email: "",
        phone: "",
        propertyValue: "",
        mortgageBalance: "",
        message: "",
        errors: { name: "", email: "", phone: "", message: "" },
        failure: "",
    };

    handleInputChange = e => {
        this.props.handleChange(e);
    };

    renderComplete() {
        return (
            <div className={styles.completeMessage}>
                <h1>Thank you</h1>
                <p>
                    Your message has been successfully sent.
                    <br />
                    We’ll get back to you as soon as possible.
                </p>
            </div>
        );
    }

    renderFailure() {
        return (
            <div className={styles.completeMessage}>
                <h1>Your message could not be delivered</h1>
                <p>
                    We apologize for any inconvenience.
                    <br />
                    Please try again later.
                </p>
            </div>
        );
    }

    renderForm() {
        const {
            name,
            propertyAddress,
            email,
            phone,
            dateOfBirth,
            propertyValue,
            mortgageBalance,
            message,
            errors,
        } = this.props;
        return (
            <div>
                <div className={styles.formBlock}>
                    <div className={styles.formHead}>
                        <h2>
                            How do I learn more about my options or get started?
                        </h2>
                    </div>
                    <p>
                        <input
                            type="text"
                            name="name"
                            placeholder="Your name*"
                            onChange={this.handleInputChange}
                            value={name}
                        />
                        <ErrorMessage error={errors.name} />
                    </p>
                    <p>
                        {/* <label>Date of Birth</label> */}
                        <input
                            type="text"
                            name="dateOfBirth"
                            placeholder="Date of Birth"
                            onChange={this.handleInputChange}
                            value={dateOfBirth}
                        />
                        {/* <ErrorMessage error={errors.email} /> */}
                    </p>
                </div>
                <div className={styles.formBlock}>
                    <p>
                        <input
                            type="text"
                            name="phone"
                            placeholder="Phone number"
                            onChange={this.handleInputChange}
                            value={phone}
                        />
                        <ErrorMessage error={errors.phone} />
                    </p>
                    <p>
                        <input
                            type="text"
                            name="propertyAddress"
                            placeholder="Property Address"
                            onChange={this.handleInputChange}
                            value={propertyAddress}
                        />
                    </p>
                </div>
                <div className={styles.formBlock}>
                    <p>
                        <input
                            type="text"
                            name="propertyValue"
                            placeholder="Estimated Property Value"
                            onChange={this.handleInputChange}
                            value={propertyValue}
                        />
                        <ErrorMessage error={errors.name} />
                    </p>

                    <p>
                        <input
                            type="text"
                            name="mortgageBalance"
                            placeholder="Estimated Mortgage Balance"
                            onChange={this.handleInputChange}
                            value={mortgageBalance}
                        />
                    </p>
                </div>
                <div>
                    <p>
                        <input
                            type="email"
                            name="email"
                            placeholder="Email address*"
                            onChange={this.handleInputChange}
                            value={email}
                        />
                        <ErrorMessage error={errors.email} />
                    </p>
                </div>
                <p>
                    <textarea
                        name="message"
                        placeholder="Message"
                        onChange={this.handleInputChange}
                        value={message}
                    />
                    <ErrorMessage error={errors.message} />
                </p>
                <div>
                    <button
                        className={styles.LinkButton}
                        label="Submit"
                        type="submit"
                        onClick={this.handleValidation}>
                        Submit
                    </button>
                </div>
            </div>
        );
    }

    render() {
        const { complete, failure } = this.props;
        if (failure) return this.renderFailure();
        return !!complete ? this.renderComplete() : this.renderForm();
    }
}

export default InnerForm;
