import React, { Component } from "react";
import "url";
import "isomorphic-fetch";

import InnerForm from "./InnerForm/";

import styles from "./styles.module.less";

const forms = ["customercare", "billing", "transfer", "sales", "other"];

class ReverseMortgageForm extends Component {
    state = {
        form: "reversemortgage",
        name: "",
        dateOfBirth: "",
        email: "",
        phone: "",
        message: "",
        mortgageBalance: "",
        propertyValue: "",
        propertyAddress: "",
        errors: { name: "", email: "", phone: "", message: "", form: "" },
        complete: false,
        failure: "",
    };

    handleSelect = e => {
        let form = e.target.value;
        if (!forms.includes(form)) form = forms[0];
        this.setState({ form: e.target.value });
    };

    handleChange = e => {
        const {
            target: { name, value },
        } = e;
        this.setState({ [name]: value });
    };

    handleSubmit = e => {
        e.preventDefault();

        if (!this.isValid()) return;

        const {
            name,
            email,
            phone,
            propertyAddress,
            message,
            dateOfBirth,
            form,
            propertyValue,
            mortgageBalance,
        } = this.state;
        const body = new URLSearchParams();
        body.append("form-name", form);
        body.append("name", name);
        body.append("email", email);
        body.append("phone", phone);
        body.append("dateOfBirth", dateOfBirth);
        body.append("propertyAddress", propertyAddress);
        body.append("propertyValue", propertyValue);
        body.append("mortgageBalance", mortgageBalance);
        body.append("message", message);

        fetch("/reverse-mortgage/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body,
        })
            .then(() => {
                this.setState({ complete: true });
            })
            .catch(err => {
                this.setState({ complete: false, failure: err.message });
            });
    };

    isValid() {
        const { name, email, phone, form } = this.state;
        let isValid = true;
        let errors = { name: "", email: "", phone: "", message: "", form: "" };
        let emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        let isPhoneValid = phone.match(
            /(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}/,
        );
        if (!form) {
            errors.form = "Must make a selection";
        }
        if (!name) {
            errors.name = "Enter a name";
            isValid = false;
        }
        if (!email) {
            errors.email = "Enter an email address";
            isValid = false;
        } else if (!emailValid) {
            errors.email = "Enter an email address";
            isValid = false;
        }
        if (phone && !isPhoneValid) {
            errors.phone =
                "Please enter a valid phone number in the format 555-555-5555";
            isValid = false;
        }
        this.setState({ errors });
        return isValid;
    }

    render() {
        const { form } = this.state;
        return (
            <div>
                <div className={styles.ContactUs}>
                    <br />
                    <div>
                        <form
                            name={form}
                            className={styles.contactForm}
                            method="POST"
                            data-netlify="true"
                            onSubmit={this.handleSubmit}>
                            <input
                                type="hidden"
                                name="form-name"
                                value={form}
                            />
                            <InnerForm
                                handleChange={this.handleChange}
                                {...this.state}
                            />
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReverseMortgageForm;
